'use client'

import { Controller, useFormContext } from 'react-hook-form'
import { Cell, Toggle, Text, Note, Spacer } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

const Vacation = () => {
  const translate = useTranslate('account_settings.vacation')
  const { control } = useFormContext()

  const note = translate('note')

  return (
    <>
      <Cell
        styling={Cell.Styling.Wide}
        title={
          <Text as="h2" type={Text.Type.Title} text={translate('title')} id="holiday-mode-toggle" />
        }
        suffix={
          <Controller
            name="isOnHoliday"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Toggle
                name={name}
                checked={value}
                onChange={onChange}
                aria={{ 'aria-labelledby': 'holiday-mode-toggle' }}
              />
            )}
          />
        }
      />
      {note ? <Note text={translate('note')} /> : <Spacer size={Spacer.Size.Large} />}
    </>
  )
}

export default Vacation
