'use client'

import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Cell, Spacer, Button, Text, Icon } from '@vinted/web-ui'
import { CancelCircleFilled32, CheckCircleFilled32, X24 } from '@vinted/monochrome-icons'

import { useClientSideOnly } from '@marketplace-web/shared/ui-helpers'
import { FlashMessageType, popFlashMessage } from '_libs/utils/flash-message'
import { isInternalUrl } from '_libs/utils/url'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useLocation } from '@marketplace-web/shared/browser'

type IconProps = ComponentProps<typeof Icon>

const flashMessageIconMap: Record<FlashMessageType, IconProps['name']> = {
  success: CheckCircleFilled32,
  warning: CancelCircleFilled32,
}

const flashMessageColorMap: Record<FlashMessageType, IconProps['color']> = {
  success: Icon.Color.GreyscaleLevel7,
  warning: Icon.Color.GreyscaleLevel7,
}

const FlashMessage = () => {
  const [flashMessage, setFlashMessage] = useState(popFlashMessage())
  const translate = useTranslate('actions')
  const messagesTranslate = useTranslate('flash_messages')
  const isClientSideRenderReady = useClientSideOnly()
  const intl = useIntl()

  const {
    searchParams: {
      flash_message_code: flashMessageCode,
      flash_message_action_code: flashMessageActionCode,
      flash_message_action_url: flashMessageActionUrl,
    },
  } = useLocation()

  useEffect(() => {
    if (!flashMessageCode) return
    if (typeof flashMessageCode !== 'string') return
    if (!intl.messages[`flash_messages.${flashMessageCode}`]) return

    const getAction = () => {
      if (!flashMessageActionCode || !flashMessageActionUrl) return undefined
      if (typeof flashMessageActionCode !== 'string' || typeof flashMessageActionUrl !== 'string') {
        return undefined
      }

      return { text: messagesTranslate(flashMessageActionCode), url: flashMessageActionUrl }
    }

    setFlashMessage({
      type: FlashMessageType.Success,
      message: messagesTranslate(flashMessageCode),
      action: getAction(),
    })
  }, [
    flashMessageActionCode,
    flashMessageActionUrl,
    flashMessageCode,
    messagesTranslate,
    intl.messages,
  ])

  const handleClose = () => setFlashMessage(null)

  if (!flashMessage || !isClientSideRenderReady) return null

  const renderMessage = () => (
    <div className="u-flexbox u-align-items-center">
      <Icon
        name={flashMessageIconMap[flashMessage.type]}
        color={flashMessageColorMap[flashMessage.type]}
        display={Icon.Display.Block}
      />
      <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
      <Text as="span" text={flashMessage.message} theme="inherit" bold />
    </div>
  )

  const renderSuffix = () => {
    const action = flashMessage?.action

    if (action && isInternalUrl(action.url)) {
      return (
        <Button
          url={action.url}
          text={action.text}
          styling={Button.Styling.Filled}
          size={Button.Size.Small}
          inverse
        />
      )
    }

    return (
      <Button
        onClick={handleClose}
        styling={Button.Styling.Flat}
        icon={<Icon name={X24} color={Icon.Color.GreyscaleLevel7} />}
        aria={{ 'aria-label': translate('close') }}
      />
    )
  }

  return (
    <>
      <Cell
        theme={flashMessage.type}
        title={renderMessage()}
        suffix={renderSuffix()}
        testId="flash-message"
      />
      <Spacer size={Spacer.Size.XLarge} />
    </>
  )
}

export default FlashMessage
